import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import api from "../../api/api";
import { getCurrentUser } from "../../utils/authService";
import useDetails from "../../hooks/useDetails";
import { GetStudentDetails } from "../../api/student";
import { toast } from "react-toastify";

const CourseHistory = () => {
  const { data: student } = useDetails();
  const [level, setLevel] = useState();
  const [registeredCourses, setRegisteredCourses] = useState([]);
  const [registeredShow, setRegisteredShow] = useState(false);
  const [session, setSession] = useState("");
  const [semesterDetails, setSemesterDetails] = useState([]);
  const [availableSessions, setAvailableSessions] = useState([]);
  const [
    currentSessionModificationStatus,
    setCurrentSessionModificationStatus,
  ] = useState(null);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const user = getCurrentUser();

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const totalRegisteredUnits = registeredCourses.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );

  const getDetails = async () => {
    setLoading(true);
    try {
      const data = await GetStudentDetails(user);
      setLevel(data?.level)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getRegisteredCourses = async () => {
    try {
      const { data } = await api.get(
        `/courses/student-view?registration_level=${level}&session=${session}`
      );
      console.log("Registered courses", data);
      setRegisteredCourses(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAvailableSession = async () => {
    try {
      const { data } = await api.get(`/setup/sessions/read`);
      setSemesterDetails(data.data);
      setAvailableSessions(data.data.map((item) => item.name).reverse());
      console.log("available sessions", data);
    } catch (error) {}
  };

  const handleFirstSemesterReset = async () => {
    const userConfirmed = window.confirm(
      `Are you sure you want to reset your First Semester Course Registration for ${level}, ${session}?`
    );
    if (!userConfirmed) return;
    try {
      const { data } = await api.post("/courses/reset/", {
        registration_session: session,
        semester: "first",
        student_id: user,
      });
      toast.success("Reset successful", successProp);
      getRegisteredCourses();
    } catch (error) {
      toast.error("Reset failed", errorProp);
    }
  };

  const handleSecondSemesterReset = async () => {
    const userConfirmed = window.confirm(
      `Are you sure you want to reset your Second Semester Course Registration for ${level}, ${session}?`
    );
    if (!userConfirmed) return;
    try {
      const { data } = await api.post("/courses/reset/", {
        registration_session: session,
        semester: "second",
        student_id: user,
      });
      toast.success("Reset successful", successProp);
      getRegisteredCourses();
    } catch (error) {
      toast.error("Reset failed", errorProp);
    }
  };

  useEffect(() => {
    if (!session) return;
    let data = semesterDetails.find((item) => item.name === session);
    setCurrentSessionModificationStatus(data.course_modifiable);
  }, [session, semesterDetails]);

  useEffect(() => {
    getDetails();
    getAvailableSession();
  }, []);

  useEffect(() => {
    getRegisteredCourses();
  }, [session, level]);

  return (
    <div class="page-wrapper">
      {student && (
        <div class="page-content-tab">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-right">
                    <ol class="breadcrumb">
                      {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                      <li class="breadcrumb-item active">
                        Course Registration
                      </li>
                    </ol>
                  </div>
                  <h4 class="page-title">History</h4>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="tab-content detail-list" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="general_detail">
                    <div class="row">
                      <div class="col-lg-12 col-xl-12 mx-auto">
                        <div class="card">
                          <div class="card-body">
                            <div class="container">
                              <div class="form-group row">
                                <div class="col-md-6">
                                  <label>Select Registration Level</label>
                                  <select
                                    class="form-control mb-3"
                                    value={level}
                                    onChange={(e) => setLevel(e.target.value)}
                                  >
                                    <option>{level}</option>
                                    <option>100</option>
                                    <option>200</option>
                                    <option>300</option>
                                    <option>400</option>
                                    <option>500</option>
                                  </select>
                                  {/* <button className="btn dspg-success mr-1">
                                    Register
                                  </button> */}
                                </div>
                                <div class="col-md-6">
                                  <label>Select Session</label>
                                  <select
                                    class="form-control mb-3"
                                    value={session}
                                    onChange={(e) => setSession(e.target.value)}
                                  >
                                    <option value=""></option>
                                    {availableSessions?.map(
                                      (session, index) => (
                                        <option key={index}>{session}</option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                              <>
                                <hr />
                                <div class="form-group mb-3">
                                  <button
                                    class="btn btn-gradient-primary btn-sm  mb-0"
                                    disabled={loading || !session}
                                    onClick={() => setRegisteredShow(true)}
                                  >
                                    View Registered Courses
                                  </button>
                                </div>
                                <hr />
                                {registeredShow && session ? (
                                  <>
                                    {registeredCourses.length ? (
                                      <>
                                        <p className="breadcrumb-item">
                                          Registered Courses
                                        </p>
                                        {/* <div className="alert dspg-info"><b>Note!</b> You cannot delete courses after the Course Advisor have approved your course registration </div> */}
                                        <table className="col-lg-9">
                                          <thead className="dspg-dark">
                                            <th>Subject</th>
                                            <th>Grade</th>
                                            <th>Unit</th>
                                            <th>Semester</th>
                                          </thead>
                                          {registeredCourses?.map((list) => (
                                            <tbody>
                                              <td>{list.code}</td>
                                              <td>{list.title}</td>
                                              <td>{list.unit}</td>
                                              <td>{list.semester}</td>
                                            </tbody>
                                          ))}
                                          <tfoot>
                                            <tr>
                                              <td className="h5">
                                                Total Units
                                              </td>
                                              <td></td>
                                              <td className="h5">
                                                {totalRegisteredUnits}
                                              </td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td colSpan={6} className="h5">
                                                Course Advisor:
                                              </td>
                                            </tr>
                                            <tr>
                                              <td colSpan={6}>
                                                {currentSessionModificationStatus && (
                                                  <>
                                                    <button
                                                      type="submit"
                                                      className="dspg-info mr-2"
                                                      onClick={
                                                        handleFirstSemesterReset
                                                      }
                                                    >
                                                      Reset First Semester
                                                    </button>
                                                    <button
                                                      type="submit"
                                                      className="dspg-dark mr-2"
                                                      onClick={
                                                        handleSecondSemesterReset
                                                      }
                                                    >
                                                      Reset Second Semester
                                                    </button>
                                                  </>
                                                )}
                                                {registeredCourses?.length && (
                                                  <button
                                                    type="submit"
                                                    className="dspg-danger"
                                                    onClick={() =>
                                                      navigate(
                                                        "/course-reg/print",
                                                        { state: session }
                                                      )
                                                    }
                                                  >
                                                    Go to Printout
                                                  </button>
                                                )}
                                              </td>
                                            </tr>
                                          </tfoot>
                                        </table>
                                      </>
                                    ) : (
                                      <div className="alert dspg-danger">
                                        No Registration Available
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </div>
  );
};

export default CourseHistory;
